import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useBoards, useReviews, useOrganizations } from '../../../adapters';
import { serializeData } from '../../../utils';

import '../../../style/page.scss';
import Page from '../../../components/page';
import ReviewTable from '../../../components/ReviewTable/ReviewTable';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  wrapperBody: {
    backgroundColor: theme.palette.common.white,
  },
}));

const ReviewsBoardPage = ({ params, ...restParams }) => {
  const searchParam = restParams?.location?.search.substring(1);
  const classes = useStyles();
  const { isFetchingBoards, getBoards, getBoard } = useBoards();
  const { isFetchingOrgs, getOrganizations } = useOrganizations();
  const { isFetchingReviews, getReviews } = useReviews();
  const [boards, setBoards] = useState([]);
  const [boardName, setBoardName] = useState('');
  const [boardImages, setBoardImages] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [count, setCount] = useState(0);
  const boardAbbr = decodeURIComponent(params.abbreviation);
  const [boardIsClicked, setBoardIsClicked] = useState(false);
  const [lastQuery, setLastQuery] = useState('');
  const [hasFilterClicked, setFilterClicked] = useState(false);

  const fetchReviews = async (query: string) => {
    setLastQuery(query);
    let queryUpdated = query;
    const hasFilter = Boolean(query.match(/developerId|status|dateRange|title/g));
    if (hasFilter) {
      setFilterClicked(true);
    }
    /* If use navigated to this page from home page by clicking on a board, then only active submissions
     * should be loaded.
     * The active submission load should be only in effect when user sorts the table or change the table's page, otherwise should be ignored
     */
    if (searchParam && !boardIsClicked && !hasFilterClicked && !hasFilter) {
      queryUpdated = `${query}&${searchParam}`;
    }
    setReviews(null);
    const res = await getReviews(queryUpdated);
    if (res && res.data) {
      setCount(res.data.count);
      setReviews(await serializeData(res.data.reviews));
    }
  };

  useEffect(() => {
    if (boardIsClicked) {
      fetchReviews(lastQuery);
    }
  }, [boardIsClicked]);

  useEffect(() => {
    let mounted = true;
    (async () => {
      // fetch boards
      const res = await getBoards({
        getActiveReviewCounts: false,
        getImages: true,
      });
      const boardImages = res.data.map((el) => ({
        imageBase64: el.imageBase64,
        imageMime: el.imageMime,
        name: el.name,
      }));
      setBoardImages(boardImages);

      if (mounted && res && res.data) {
        const arr = [{ abbr: 'All', label: 'All' }];
        res.data.forEach((board) => {
          arr.push({
            abbr: board.abbreviation.toLowerCase(),
            label: board.abbreviation,
          });
        });
        setBoards(arr);
      }
    })();

    (async () => {
      // fetch organizations
      const orgs = await getOrganizations();
      if (mounted && orgs && orgs.data) {
        setOrganizations(orgs.data);
      }
    })();

    return () => (mounted = false);
  }, []);

  useEffect(() => {
    (async () => {
      const res = await getBoard(boardAbbr);
      if (res && res.data) setBoardName(res.data.name);
    })();
  }, [boardAbbr]);

  return (
    <Page isFetching={isFetchingBoards || isFetchingOrgs} wrapperBody={classes.wrapperBody}>
      <ReviewTable
        isFetchingReviews={isFetchingReviews}
        organizations={organizations}
        boards={boards}
        reviews={reviews}
        totalReviewsCount={count}
        boardAbbr={boardAbbr}
        boardName={boardName}
        boardImages={boardImages}
        onUpdate={async (query) => await fetchReviews(query)}
        onBoardClicked={() => setBoardIsClicked(true)}
      />
    </Page>
  );
};

ReviewsBoardPage.propTypes = {
  params: PropTypes.any,
};

export default ReviewsBoardPage;
